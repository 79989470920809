exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acupuncture-js": () => import("./../../../src/pages/acupuncture.js" /* webpackChunkName: "component---src-pages-acupuncture-js" */),
  "component---src-pages-acupuncture-pansy-js": () => import("./../../../src/pages/acupuncture_pansy.js" /* webpackChunkName: "component---src-pages-acupuncture-pansy-js" */),
  "component---src-pages-acupuncture-systems-js": () => import("./../../../src/pages/acupuncture_systems.js" /* webpackChunkName: "component---src-pages-acupuncture-systems-js" */),
  "component---src-pages-chinese-medicine-js": () => import("./../../../src/pages/chinese_medicine.js" /* webpackChunkName: "component---src-pages-chinese-medicine-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact_us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-counselling-js": () => import("./../../../src/pages/counselling.js" /* webpackChunkName: "component---src-pages-counselling-js" */),
  "component---src-pages-current-research-js": () => import("./../../../src/pages/current_research.js" /* webpackChunkName: "component---src-pages-current-research-js" */),
  "component---src-pages-dr-henry-js": () => import("./../../../src/pages/dr_henry.js" /* webpackChunkName: "component---src-pages-dr-henry-js" */),
  "component---src-pages-dr-pansy-js": () => import("./../../../src/pages/dr_pansy.js" /* webpackChunkName: "component---src-pages-dr-pansy-js" */),
  "component---src-pages-food-cures-js": () => import("./../../../src/pages/food_cures.js" /* webpackChunkName: "component---src-pages-food-cures-js" */),
  "component---src-pages-herbal-formulas-js": () => import("./../../../src/pages/herbal_formulas.js" /* webpackChunkName: "component---src-pages-herbal-formulas-js" */),
  "component---src-pages-hypnotherapy-js": () => import("./../../../src/pages/hypnotherapy.js" /* webpackChunkName: "component---src-pages-hypnotherapy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-laser-therapy-js": () => import("./../../../src/pages/laser_therapy.js" /* webpackChunkName: "component---src-pages-laser-therapy-js" */),
  "component---src-pages-osteopathy-js": () => import("./../../../src/pages/osteopathy.js" /* webpackChunkName: "component---src-pages-osteopathy-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-pregnancy-js": () => import("./../../../src/pages/pregnancy.js" /* webpackChunkName: "component---src-pages-pregnancy-js" */),
  "component---src-pages-scientific-studies-js": () => import("./../../../src/pages/scientific_studies.js" /* webpackChunkName: "component---src-pages-scientific-studies-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-whats-new-js": () => import("./../../../src/pages/whats_new.js" /* webpackChunkName: "component---src-pages-whats-new-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

